import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import theanewlogo from  "../images/theanew.jpg"

import s70v from  "../images/s70v.jpg"


const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      zIndex: 4000,
    }}
  >
    <div
      style={{
        width:`100vw`,
        display: `flex`,
      }}
      
    >
      <img className="site-logo" src={theanewlogo} />


    </div>
    
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
